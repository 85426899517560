<template>
  <div class="flex flex-col w-full h-full min-h-full">
    <AppHeader></AppHeader>
    <div class="flex flex-grow overflow-hidden">
      <AppAside />
      <slot></slot>
    </div>
    <!-- <AppFooter></AppFooter> -->
  </div>
</template>

<script>
import AppHeader from '@/components/core/AppHeader.vue'
import AppAside from '@/components/core/AppAside.vue'
// import AppFooter from '@/components/core/AppFooter.vue'

export default {
  name: 'SecondaryLayout',
  components: {
    AppHeader,
    AppAside
    // AppFooter
  }
}
</script>
